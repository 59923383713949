
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css'
import 'flatpickr/dist/themes/dark.css';



flatpickr(".datepicker_flatpickr", {

});

['turbo:load','element_inserted'].forEach(function(e) {
 
  $(document).on(e, function() {
    $(".datepicker_flatpickr").flatpickr({
    
    });
  });

  $(document).on(e, function() {
    $(".datetimepicker_flatpickr").flatpickr({
        enableTime: true,
    });
  });

});
